<template>
    <PromiseContainer :promises.sync="promises">
    <Overview
        :items="invoices"
        :headers="headers"
        :filters="filters"
        header-text="Facturen"
        add-btn-text="Nieuwe factuur"
        :add-item-route="{name: RouteNames.INVOICE_CREATE}"
        :detail-page-function="detailPageFunction">
      <template v-slot:item.credit="{item}">
        <span>{{ item.credit ? 'Creditnota' : 'Factuur' }}</span>
      </template>
      <template v-slot:item.statusType="{item}">
        <v-chip :color="getInvoiceStatusTypeColor(item.statusType)" label class="max-h-36px">
            {{ translateInvoiceStatusType(item.statusType) }}
        </v-chip>
      </template>
      <template v-slot:item.total="{item}">
        {{ item.total?.toFixed(2) }}
      </template>
    </Overview>
  </PromiseContainer>
</template>

<script>
import PromiseContainer from "@/components/shared/PromiseContainer.vue"
import Overview from "@/components/shared/Overview";
import RouteNames from "@/router/RouteNames";
import { translateInvoiceStatusType, getInvoiceStatusTypeColor } from "@/shared/utils/translateUtils";
import InvoiceStatusType from "@/shared/enums/invoiceStatusType";

export default {
    name: "InvoiceOverview",
    components: {
        PromiseContainer,
        Overview
    },
    data() {
      return {
        RouteNames,
        invoices: [],
        headers: [
            {
                text: "Factuurnummer",
                value: 'invoiceNumber',
                cellClass: 'notranslate'
            },
            {
                text: "Type",
                value: 'credit'
            },
            {
                text: "Klant",
                value: 'organizationToName',
                cellClass: 'notranslate'
            },
            {
                text: "Factuurdatum",
                value: 'invoiceDate'
            },
            {
                text: "Bedrag",
                value: 'total'
            },
            {
              text: "Status",
              value: 'statusType'
            }
        ],
        filters: [],
        promises: [this.fetchInvoices()],
        detailPageFunction: (invoice) => {
        return {
          name: invoice.statusType == InvoiceStatusType.DRAFT ? RouteNames.INVOICE_EDIT : RouteNames.INVOICE_DETAIL,
          params: {
            invoiceId: invoice.id
          }
        }
      },
      }
    },
    computed: {
      getClientOptions() {
        const clients = [... new Set(this.invoices.map(invoice => invoice.organizationToName))]
        return clients.map(client => ({
          id: 'invoice#client#' + client,
          label: client,
          active: false,
          predicate: (invoice) => invoice.organizationToName && invoice.organizationToName === client
        }))
      }
    },
    methods: {
      translateInvoiceStatusType,
      getInvoiceStatusTypeColor,
      fetchInvoices() {
        this.$store.dispatch("invoicesModule/fetchInvoices")
        .then(data => {
          this.invoices = data
          this.resetFilters()
        })
      },
      resetFilters() {
        this.filters = [
          {
            title: 'Status',
            options: [
              {
                id: 'invoice#status#draft',
                label: 'Ontwerp',
                active: false,
                predicate: (invoice) => invoice.statusType && invoice.statusType === "DRAFT"
              },
              {
                id: 'invoice#status#open',
                label: 'Open',
                active: false,
                predicate: (invoice) => invoice.statusType && invoice.statusType === "OPEN"
              },
              {
                id: 'invoice#status#paid',
                label: 'Betaald',
                active: false,
                predicate: (invoice) => invoice.statusType && invoice.statusType === "PAID"
              },
            ]
          },
          {
            title: 'Klant',
            options: this.getClientOptions
          },
          {
            title: 'Type',
            options: [
              {
                id: 'invoice#credit#true',
                label: 'Creditnota',
                active: false,
                predicate: (invoice) => invoice.credit
              },
              {
                id: 'invoice#credit#false',
                label: 'Factuur',
                active: false,
                predicate: (invoice) => !invoice.credit
              }
            ]
          },
        ]
      }
    }
}
</script>